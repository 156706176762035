import React, { useState } from "react";

import { Form, Input, message } from "antd";

import REQUESTS from "../../../api/requests";

import { InfoCircleOutlined } from "@ant-design/icons";

import useTranslate from "../../../hooks/translator";
import { ButtonComponent } from "../../../components";

export default function CodeStep({ setStep }) {
  const translate = useTranslate();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    const body = {
      two_fa_code: values.two_fa_code,
    };
    setLoading(true);
    REQUESTS.OTP.TWO_FA_ACTIVE(body)
      .then((res) => {
        setStep("disabledTwoFa");
        setLoading(false);
        message.success(res);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err);
      });
  };

  const submitBtn = (e) => {
    e.stopPropagation();
    form.submit();
  };

  return (
    <Form form={form} onFinish={onFinish} layout="vertical">
      <Form.Item
        label={translate("Code")}
        name="two_fa_code"
        rules={[
          {
            required: true,
            message: translate("Please input your verify code"),
          },
          {
            min: 6,
            message: translate("Verify code must be 6 characters"),
          },
        ]}
      >
        <Input
          maxLength={6}
          placeholder={translate("Enter code")}
          style={{ width: "140px", textAlign: "center" }}
        />
      </Form.Item>
      <div style={{ fontSize: 14, margin: "10px 0px" }}>
        <InfoCircleOutlined
          style={{
            color: "#7f6e15",
          }}
        />{" "}
        {translate("Two-Factor Authentication (2FA) Code Reminder")}
      </div>
      <Form.Item>
        <ButtonComponent
          style={{ width: 120 }}
          onClick={(e) => submitBtn(e)}
          loading={loading}
        >
          {translate("Enable 2FA")}
        </ButtonComponent>
      </Form.Item>
    </Form>
  );
}

import { Modal } from "antd";

import { ICONS } from "../../config";

import "./result-modal.scss";

function resultModal({ type = "Success", response }) {
  return Modal.info({
    className: "result-modal-component",
    wrapClassName: "result-modal-component-wrap",
    closable: true,
    maskClosable: true,
    icon: null,
    okButtonProps: {
      type: "primary",
      className: "result-modal-ok-button"
    },

    content: (
      <div className="result-modal-content">
        <div className="result-modal-header">
          {type === "Success" ? ICONS.SUCCESS : ICONS.ERROR}
        </div>

        <div className="result-modal-body">
          <p>{response}</p>
        </div>
      </div>
    )
  });
}

export default resultModal;

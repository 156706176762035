import React, { useState } from "react";
import { Tabs, message } from "antd";

import useTranslate from "../../hooks/translator";

import { ButtonComponent, PageComponent, confirmModal } from "../../components";

import LiveTvPage from "./LiveTvPage";
import MoviesPage from "./MoviesPage";
import SeriesPage from "./SeriesPage";
import REQUESTS from "../../api/requests";

export default function ErrorLogsPage() {
  const translate = useTranslate();

  const [activeTab, setActiveTab] = useState("live_tv");

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [loading, setLoading] = useState(false);

  const [getLiveTV, setGetLiveTV] = useState(false);
  const [getMovies, setGetMovies] = useState(false);
  const [getSeries, setGetSeries] = useState(false);

  const send = () => {
    setLoading(true);

    setGetLiveTV(false);
    setGetMovies(false);
    setGetSeries(false);

    const body = {
      ids: selectedRowKeys,
    };

    REQUESTS.ERROR_LOGS_Del(body)
      .then((res) => {
        setSelectedRowKeys([]);
        setLoading(false);

        if (activeTab == "live_tv") {
          setGetLiveTV(true);
        }

        if (activeTab == "movies") {
          setGetMovies(true);
        }

        if (activeTab == "series") {
          setGetSeries(true);
        }

        message.success(res);
      })
      .catch((err) => {
        setSelectedRowKeys([]);
        setLoading(false);
        message.error(err);
      });
  };

  const onDelete = () => {
    confirmModal({
      title: translate("Are you sure you want to delete"),
      okText: translate("Yes"),
      cancelText: translate("Cancel"),
      action: () => send(),
    });
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;

  const onChange = (key) => {
    setActiveTab(key);
  };

  const items = [
    {
      key: "live_tv",
      label: translate("Live TV"),
      children: (
        <LiveTvPage
          translate={translate}
          rowSelection={rowSelection}
          get={getLiveTV}
        />
      ),
    },
    {
      key: "movies",
      label: translate("Movies"),
      children: (
        <MoviesPage
          translate={translate}
          rowSelection={rowSelection}
          get={getMovies}
        />
      ),
    },
    {
      key: "series",
      label: translate("Series"),
      children: (
        <SeriesPage
          translate={translate}
          rowSelection={rowSelection}
          get={getSeries}
        />
      ),
    },
  ];

  return (
    <PageComponent>
      <div className="head-page">
        <h3>{translate("Error logs")}</h3>
        <ButtonComponent
          onClick={onDelete}
          disabled={!hasSelected}
          loading={loading}
          style={{ width: "100px" }}
        >
          {translate("Delete")}
        </ButtonComponent>
      </div>
      <Tabs defaultActiveKey="live_tv" items={items} onChange={onChange} />
    </PageComponent>
  );
}

import React from "react";
import { ICONS } from "../../../config";

export default function MobilePackage({ item, isSelected, onSelect }) {
  return (
    <div
      key={item.id}
      className={`package-container ${isSelected ? "selected-package" : ""}`}
    >
      <div className={`package`} onClick={onSelect}>
        <div className="circle">
          {isSelected && <div className="selected-circle" />}
        </div>

        <div className="package-body">
          <div className="package-title-block">
            <div className="package-title-block-wrap">
              <div className="package-title">{item?.name}</div>

              {item.discount && <div className="sale">{item?.discount}</div>}

              {item?.is_popular && (
                <div className="trending-package trending-mobile">
                  Most Popular
                </div>
              )}
            </div>
          </div>

          <div className="price_br">
            R$ {item?.brl_price}{" "}
            <del>{item?.old_brl_price && `R$ ${item?.old_brl_price}`}</del>
          </div>
        </div>

        <div className="package-credit">
          <div className="price_dollar_mobile">
            <del>{item?.old_price && `$${item?.old_price}`}</del>${item?.price}
          </div>
          <div className="package_credit_body">
            <span className="package-icon">{ICONS.TRUE}</span>
            <span>Credit {item?.credit}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

import { useState } from "react";

import { Modal, Form } from "antd";

import { ICONS } from "../config";

import useTranslate from "../hooks/translator";

import REQUESTS from "../api/requests";

import {
  InputComponent,
  ButtonComponent,
  MessageComponent
} from "../components";

export default function TokenRefresh({ open, onCancel, onSuccess }) {
  const translate = useTranslate();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [messageErr, setMessageErr] = useState("");

  const onFinish = (values) => {
    setLoading(true);
    setMessageErr("");

    const body = {
      password: values.password.trim()
    };

    if (body) {
      REQUESTS.TOKEN_REFRESH(body)
        .then((res) => {
          onSuccess();
          localStorage.setItem("TOKEN", res.token);
          localStorage.setItem("refresh_token", JSON.stringify(res));
          form.resetFields();
          setLoading(false);
          onCancel();
        })
        .catch((err) => {
          if (err == "Incorrect email or password") {
            setMessageErr(translate("Incorrect password"));
          } else {
            setMessageErr(translate("Something went wrong, try again"));
          }
          setLoading(false);
        });
    }
  };
  const onFieldsChange = () => {
    setMessageErr("");
    setLoading(false);
  };

  return (
    <Modal
      title={translate("Please input your password")}
      width={370}
      open={open}
      onCancel={onCancel}
      footer={null}
      maskClosable={false}
      closeIcon={false}
    >
      <Form
        name="password"
        layout="vertical"
        form={form}
        onFieldsChange={onFieldsChange}
        onFinish={onFinish}
      >
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: translate("Please input your password")
            },
            {
              min: 8,
              message: translate("Password must be minimum 8 characters")
            }
          ]}
        >
          <InputComponent
            type="password"
            prefix={ICONS.PASSWORD}
            placeholder={translate("Password")}
          />
        </Form.Item>

        <MessageComponent message={messageErr} />

        <Form.Item>
          <ButtonComponent
            type="primary"
            htmlType="submit"
            loading={loading}
            style={{ marginTop: 20, width: "100%" }}
          >
            {translate("Save")}
          </ButtonComponent>
        </Form.Item>
      </Form>
    </Modal>
  );
}

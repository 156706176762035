import { Button, Result } from "antd";
import { useNavigate, useLocation } from "react-router-dom";

import useTranslate from "../hooks/translator";

export default function NotFound() {
  const navigate = useNavigate();
  const location = useLocation();
  const translate = useTranslate();

  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        location.pathname !== "/dashboard/subresellers" ? (
          <Button
            type="primary"
            onClick={() => navigate("/dashboard")}
            style={{ width: "100px", borderRadius: "4px" }}
          >
            {translate("Back Home")}
          </Button>
        ) : (
          <></>
        )
      }
    />
  );
}

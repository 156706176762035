import React, { useContext } from "react";

import { BrandingContext } from "../BrandingContext";

import { top_movies, top_series } from "../../../config/data";

import Logo from "./Logo";
import ListsMedia from "./ListsMedia";
import MenuRmsButtons from "./MenuRmsButtons";
import ReloadApp from "./ReloadApp";

import "../styles/menu-rms.scss";

export default function MenuRms() {
  const { backgroundImage, logo, logoSize, secondaryColor } =
    useContext(BrandingContext);

  return (
    <div
      className="menu-page-two display-width"
      style={{
        backgroundImage: `url(${backgroundImage?.url || backgroundImage})`,
        backgroundColor: "#000",
      }}
    >
      <div className="menu-page-wrap">
        <div className="left-section">
          <div className="logo" style={{ marginBottom: "18px" }}>
            <Logo logo={logo} logoSize={logoSize} />
          </div>

          <MenuRmsButtons />

          <div className="rms-wrap-reload">
            <ReloadApp />
          </div>
        </div>
        <div className="right-section">
          <ListsMedia
            data={top_movies}
            title="10 Recently Added Movies"
            secondaryColor={secondaryColor}
            isScale={true}
          />
          <ListsMedia
            data={top_series}
            title="10 Recently Added Series"
            secondaryColor="transparent"
          />
        </div>
      </div>
      <div className="menu-page-two-tv-footer">
        <p>
          <span> Device Key: 3GK689</span>
        </p>
        <p>App version: 1.1.5</p>
      </div>
    </div>
  );
}

import URLS from "./urls";
import request from "./request";
import axios from "axios";

const createRequest = (method, url) => (body) => request(method, url, body);

const REQUESTS = {
  // a
  AVAILABLE_OS: createRequest("get", URLS.AVAILABLE_OS),
  APP_BRANDINGS: {
    PUT: createRequest("put", URLS.APP_BRANDINGS),
    GET: createRequest("get", URLS.APP_BRANDINGS),
  },
  APP_RESET: {
    GET: createRequest("get", URLS.APP_RESET),
    POST: createRequest("post", URLS.APP_RESET),
    PUT: createRequest("put", URLS.APP_RESET),
  },
  ALERTS_MESSAGES: {
    PUT: createRequest("put", URLS.ALERTS_MESSAGES),
    GET: createRequest("get", URLS.ALERTS_MESSAGES),
  },
  ALERTS_HISTORY: createRequest("get", URLS.ALERTS_HISTORY),
  ADD_ACTIVATION_DEVICE: createRequest("post", URLS.ADD_ACTIVATION_DEVICE),
  ACTIVATE_DEVICE: createRequest("put", URLS.ACTIVATE_DEVICE),

  // b
  BILLING_HISTORY: createRequest("get", URLS.BILLING_HISTORY),
  BRAND_RESET: createRequest("put", URLS.BRAND_RESET),
  BTC: createRequest("post", URLS.BTC),
  COINBASE_V2: createRequest("post", URLS.COINBASE_V2),
  BANNER_AD: {
    GET: createRequest("get", URLS.BANNER_AD),
    POST: createRequest("post", URLS.BANNER_AD),
    PUT: createRequest("put", URLS.BANNER_AD),
    DELETE: createRequest("delete", URLS.BANNER_AD),
  },
  // c
  CHANGE_PASSWORD: createRequest("post", URLS.CHANGE_PASSWORD),
  COUNTRY_LIST: createRequest("get", URLS.COUNTRY_LIST),
  CHECK_PLAYLIST: (url, username, password) =>
    axios({
      url,
      method: "GET",
      maxRedirects: 10,
      timeout: 60000,
      params: {
        username,
        password,
      },
      headers: {
        "content-type": "application/json",
      },
    }),

  // d
  DEVICES: createRequest("get", URLS.DEVICES),
  DEVICES_INFO: createRequest("get", URLS.DEVICES_INFO),
  DEVICES_TOTAL_INFO: createRequest("get", URLS.DEVICES_TOTAL_INFO),

  DEACTIVATE_DEVICE: createRequest("put", URLS.DEACTIVATE_DEVICE),
  DEVICE_UPDATE: createRequest("put", URLS.DEVICE_UPDATE),
  DEACTIVATED_DEVICES_LIST: createRequest("get", URLS.DEACTIVATED_DEVICES_LIST),
  DEVICE_BLACK_LIST: {
    GET: createRequest("get", URLS.DEVICE_BLACK_LIST),
    POST: createRequest("post", URLS.DEVICE_BLACK_LIST),
    DELETE: createRequest("delete", URLS.DEVICE_BLACK_LIST),
  },
  DEVICE_INFO: createRequest("get", URLS.DEVICE_INFO),
  // e
  EDIT_PROFILE: createRequest("put", URLS.EDIT_PROFILE),
  ERROR_LOGS: createRequest("get", URLS.ERROR_LOGS),
  ERROR_LOGS_Del: createRequest("delete", URLS.ERROR_LOGS),

  // f
  FORGOT_PASSWORD: createRequest("post", URLS.FORGOT_PASSWORD),

  // g
  GEOLOCATION_STATISTICS: createRequest("get", URLS.GEOLOCATION_STATISTICS),
  GET_LIVES: createRequest("get", URLS.LIVES),
  GET_MOVIES: createRequest("get", URLS.MOVIES),

  // h
  // i
  // INECOBANK: createRequest("post", URLS.INECOBANK),
  // j
  // k
  // l
  LIVES_CHART: createRequest("get", URLS.LIVES_CHART),
  LOGIN: createRequest("post", URLS.LOGIN),

  // m
  MOVIES_CHART: createRequest("get", URLS.MOVIES_CHART),
  MIGRATION: {
    GET: createRequest("get", URLS.MIGRATE),
    PUT: createRequest("put", URLS.MIGRATE),
  },
  MULTI_ACCOUNT: {
    GET: createRequest("get", URLS.MULTI_ACCOUNT),
    POST: createRequest("post", URLS.MULTI_ACCOUNT),
    DELETE: createRequest("delete", URLS.MULTI_ACCOUNT),
    CODE: createRequest("get", `${URLS.MULTI_ACCOUNT}/code`),
    SELECT: createRequest("post", `${URLS.MULTI_ACCOUNT}/select`),
  },
  // n
  // NOTIFICATIONS: {
  //   GET: createRequest("get", URLS.NOTIFICATIONS),
  //   POST: createRequest("post", URLS.NOTIFICATIONS),
  //   DELETE: createRequest("delete", URLS.NOTIFICATIONS),
  //   DEVICES: createRequest("get", `${URLS.NOTIFICATIONS}/devices`),
  // },

  // o
  OTP: {
    TWO_FA_POST: createRequest("post", URLS.OTP),
    TWO_FA_ACTIVE: createRequest("post", `${URLS.OTP}/activate`),
    TWO_FA_PUT: createRequest("put", `${URLS.OTP}/disable`),
  },
  // p
  PROFILE: createRequest("get", URLS.PROFILE),
  PACKAGES: createRequest("get", URLS.PACKAGES),
  PLATFORMS_STATISTICS: createRequest("get", URLS.PLATFORMS_STATISTICS),
  PLATFORMS: createRequest("get", URLS.PLATFORMS),
  PROVIDER_LIMITED: createRequest("put", URLS.PROVIDER_LIMITED),
  PAYMENT_PIX: createRequest("post", URLS.PAYMENT_PIX),
  PARTNER_PACKAGE: createRequest("get", URLS.PARTNER_PACKAGE),
  // q
  // r
  REGISTERED_CHART: createRequest("get", URLS.REGISTERED_CHART),
  RESET_PASSWORD: createRequest("post", URLS.RESET_PASSWORD),

  // s
  SERVER_HOSTS: {
    GET: createRequest("get", URLS.SERVER_HOSTS),
    POST: createRequest("post", URLS.SERVER_HOSTS),
    DELETE: createRequest("delete", URLS.SERVER_HOSTS),
    PUT: createRequest("put", URLS.SERVER_HOSTS),
    IS_DEFAULT: createRequest(
      "put",
      `${URLS.SERVER_HOSTS}/mark_server_as_default`
    ),
    GET_DEVICES_COUNT: createRequest(
      "get",
      `${URLS.SERVER_HOSTS}/device_count`
    ),
  },

  SERVER_HOSTS_FOR_SUB: createRequest("get", URLS.SERVER_HOSTS_FOR_SUB),
  SUBRESELLERS: {
    GET: createRequest("get", URLS.SUBRESELLERS),
    POST: createRequest("post", URLS.SUBRESELLERS),
    BLOCK: createRequest("put", `${URLS.SUBRESELLERS}/block`),
    PUT: createRequest("put", URLS.SUBRESELLERS),
    ADD_CREDIT: createRequest("post", `${URLS.SUBRESELLERS}/add_credit`),
    TAKE_CREDIT: createRequest("post", `${URLS.SUBRESELLERS}/take_credit`),
    INFO: createRequest("get", `${URLS.SUBRESELLERS}/by_id`),
  },
  SUB_RESELLERS_DEVICES: createRequest("get", URLS.SUB_RESELLERS_DEVICES),
  SUB_RESELLERS_APPROVE: createRequest("put", URLS.SUB_RESELLERS_APPROVE),
  SUB_RESELLER_CREDITS: createRequest("get", URLS.SUB_RESELLER_CREDITS),
  SUB_RESELLER_BILLING: createRequest("get", URLS.SUB_RESELLER_BILLING),
  SUB_DEVICES_COUNT: createRequest("get", URLS.SUB_DEVICES_COUNT),
  SUB_RESELLERS_MULTI: {
    GET: createRequest("get", URLS.SUB_RESELLERS_MULTI),
  },
  SUB_ADD_CREDIT_BY_SUB: createRequest("post", URLS.SUB_ADD_CREDIT_BY_SUB),
  SUB_TAKE_CREDIT_BY_SUB: createRequest("post", URLS.SUB_TAKE_CREDIT_BY_SUB),
  SETTINGS_CONTACTS: {
    GET: createRequest("get", URLS.SETTINGS_CONTACTS),
    PUT: createRequest("put", URLS.SETTINGS_CONTACTS),
    POST: createRequest("post", URLS.SETTINGS_CONTACTS),
  },
  SYNC_PLAYLIST: createRequest("put", URLS.SYNC_PLAYLIST),

  // t
  TOKEN_REFRESH: createRequest("post", URLS.TOKEN_REFRESH),
  // u
  // v
  // VIEWED_MOVIE: createRequest("get", URLS.VIEWED_MOVIE),
  // VIEWED_CHART: createRequest("get", URLS.VIEWED_CHART),
  VALIDATE_MAC: createRequest("get", URLS.VALIDATE_MAC),

  // w
  // x
  // y
  // z
};

export default REQUESTS;

import { useState, useEffect } from "react";

export function useDnsOptions(servers = []) {
  const [hosts, setHosts] = useState([]);

  useEffect(() => {
    if (servers.length > 0) {
      const newList = servers.map((server) => {
        return {
          label: server.name,
          value: server.id,
          ...server
        };
      });

      setHosts(newList);
    }
  }, []);

  return hosts;
}

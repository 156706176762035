import { useState, useEffect } from "react";
import { Table, Tag } from "antd";

import REQUESTS from "../../api/requests";

import useTranslate from "../../hooks/translator";

import { parseFullDate } from "../../config/formats";

import { Tags, Check, LongText, CopyText } from "../../components";

export default function DevicesSubreseller({ itemId, tab }) {
  const translate = useTranslate();

  const [loading, setLoading] = useState(false);

  const [devices, setDevices] = useState([]);

  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const columns = [
    {
      title: "#",
      width: 40,
      dataIndex: "id",
      key: "id",
      fixed: "left",
      align: "center",
      render: (text, record, index) => {
        return limit * (currentPage - 1) + index + 1;
      },
    },
    {
      title: translate("Device key"),
      dataIndex: "device_key",
      key: "device_key",
      align: "center",
      fixed: "left",
      render: (text, record, index) =>
        text ? <CopyText text={text} /> : "N/A",
    },

    {
      title: translate("App name"),
      key: "app_name",
      align: "center",
      render: (text, record, index) => (
        <div>{record?.app_name ? record?.app_name : "N/A"}</div>
      ),
    },

    {
      title: translate("IP address"),
      dataIndex: "ip_address",
      key: "ip_address",
      align: "center",
      render: (text, record, index) =>
        record?.ip_address ? (
          <CopyText text={<LongText children={record?.ip_address} />} />
        ) : (
          "N/A"
        ),
    },
    {
      title: translate("Playlist"),
      dataIndex: "playlist",
      key: "playlist",
      align: "center",
      render: (text, record, index) =>
        record?.playlist ? (
          <CopyText text={<LongText children={record?.playlist} />} />
        ) : (
          "N/A"
        ),
    },
    {
      title: translate("Last online"),
      dataIndex: "online_date",
      key: "online_date",
      align: "center",
      render: (text, record, index) => {
        const lastOnline = new Date(record?.online_date);
        return (
          <div>
            {record?.is_online ? (
              <span style={{ color: "green" }}>{translate("Online")}</span>
            ) : record.online_date ? (
              parseFullDate(lastOnline)
            ) : (
              "N/A"
            )}
          </div>
        );
      },
    },
    {
      title: translate("Platform"),
      key: "platform name",
      align: "center",
      render: (text, record, index) => {
        return (
          (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <img
                src={record?.platform?.icon}
                style={{
                  width: "fit-content",
                  height: "23px",
                  marginRight: "10px",
                  objectFit: "contain",
                }}
                alt=""
              />
              <span>{record?.platform?.name}</span>
            </div>
          ) || <Tags />
        );
      },
    },

    {
      title: translate("Status"),
      dataIndex: "is_active",
      key: "is_active",
      align: "center",
      render: (text, record, index) =>
        record.is_active ? (
          <Tag color="green">Active</Tag>
        ) : !record.is_active && record.is_trial ? (
          <Tag color="gold">Trial</Tag>
        ) : (
          <Tag color="red">Disabled</Tag>
        ),
    },
    {
      title: translate("Auto renew"),
      dataIndex: "auto_renew",
      key: "auto_renew",
      align: "center",
      render: (text, record, index) => <Check check={record.auto_renew} />,
    },
    {
      title: translate("Country"),
      dataIndex: "country",
      key: "country",
      align: "center",
      render: (text, record, index) =>
        record?.country ? record?.country : "N/A",
    },
    {
      title: translate("Free trial"),
      dataIndex: "is_trial",
      key: "is_trial",
      align: "center",
      render: (text, record, index) => <Check check={record.is_trial} />,
    },

    {
      title: translate("Free trial expired"),
      dataIndex: "free_trial_expired",
      key: "free_trial_expired",
      align: "center",
      render: (text, record, index) => parseFullDate(text),
    },

    {
      title: translate("Created date"),
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      render: (text, record, index) => parseFullDate(text),
    },
  ];

  const getDevices = () => {
    setLoading(true);

    const query = {
      limit,
      page: currentPage,
      pagination: 1,
      sort: ["id", "DESC"],
      filter: { provider_id: itemId },
    };

    REQUESTS.SUB_RESELLERS_DEVICES({ query: JSON.stringify(query) })
      .then((data) => {
        setLoading(false);
        setDevices(data.rows);
        setTotal(data.total);
        setLimit(data.limit);
        setCurrentPage(data.page);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (tab == "Devices" && itemId) {
      getDevices();
    }
  }, [currentPage, limit, itemId, tab]);

  return (
    <Table
      rowKey="id"
      size={"small"}
      dataSource={devices}
      columns={columns}
      loading={loading}
      pagination={{
        position: ["bottomCenter"],
        current: currentPage,
        total: total,
        pageSize: limit,
        showSizeChanger: true,
      }}
      scroll={{
        x: "max-content",
        y: null,
      }}
    />
  );
}

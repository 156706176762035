import { Button, Table, message } from "antd";
import React, { useState, useEffect, useRef } from "react";

import REQUESTS from "../../../api/requests";

import useTranslate from "../../../hooks/translator";
import { ICONS } from "../../../config";
import { parseFullDate } from "../../../config/formats";
import {
  getColumnDateProps,
  getColumnSearchProps,
  getQueryDate,
} from "../../../config/filters";

import { PageComponent, confirmModal, CopyText } from "../../../components";

export default function ResetRequestsDevices() {
  const translate = useTranslate();
  const searchInputRef = useRef(null);

  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pendingCount, setPendingCount] = useState(0);

  const [search, setSearch] = useState({
    deviceId: null,
    deviceKey: null,
    deviceOs: null,
  });

  const [filtersTb, setFiltersTb] = useState({
    date: null,
    state: pendingCount == 0 ? null : "pending",
  });

  const [sort, setSort] = useState(["id", "DESC"]);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortedInfo, setSortedInfo] = useState({});

  useEffect(() => {
    getData();
  }, [search, filtersTb, currentPage]);

  const columns = [
    {
      width: 60,
      title: "#",
      fixed: "left",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (text, record, index) => {
        return limit * (currentPage - 1) + index + 1;
      },
    },
    {
      title: translate("Device key"),
      dataIndex: "device_key",
      key: "device_key",
      align: "center",
      fixed: "left",
      ...getColumnSearchProps(searchInputRef),
      render: (text, record, index) =>
        text ? <CopyText text={text} /> : "N/A",
    },
    {
      title: translate("Device OS"),
      dataIndex: "device_os",
      key: "device_os",
      align: "center",
      ...getColumnSearchProps(searchInputRef),
      render: (text, record, index) => text,
    },
    {
      title: translate("State"),
      dataIndex: "state",
      key: "state",
      align: "center",
      defaultFilteredValue: filtersTb.state,
      filters: [
        {
          text: "Pending",
          value: "pending",
        },
        {
          text: "Reseted",
          value: "reseted",
        },
        {
          text: "Canceled",
          value: "canceled",
        },
      ],
      render: (text, record, index) =>
        (
          text?.charAt(0)?.toUpperCase() + text?.slice(1)?.toLowerCase()
        )?.replaceAll("_", " ") || "N/A",
    },
    {
      title: translate("Date"),
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      sorter: true,
      sortOrder: sortedInfo.columnKey === "createdAt" ? sortedInfo.order : null,
      ...getColumnDateProps((e, dateString) =>
        setFiltersTb({ ...filtersTb, date: dateString })
      ),
      render: (text, record, index) => parseFullDate(text),
    },
    {
      title: "",
      key: "row__actions",
      fixed: "right",
      width: 60,
      align: "center",
      render: (text, record, index) =>
        record.state == "pending" && (
          <div className="reset-cancel-app">
            <Button
              icon={ICONS.RESET_APP}
              onClick={(e) => handleMenuClick(e, record, "reset")}
            >
              {translate("Reset")}
            </Button>
            <Button
              icon={ICONS.UNRESET_APP}
              onClick={(e) => handleMenuClick(e, record, "cancel")}
            >
              {translate("Cancel")}
            </Button>
          </div>
        ),
    },
  ];

  const getData = () => {
    setLoading(true);

    const query = {
      limit,
      page: currentPage,
      pagination: 1,
      sort,
    };

    if (search) {
      const list = Object.values(search);
      for (let i = 0; i < list.length; i++) {
        if (list[i]) {
          if (!query.search) {
            query.search = {};
          }
          break;
        }
        delete query.search;
      }

      if (search.deviceKey) {
        query.search["device_key"] = search.deviceKey[0];
      }
      if (search.deviceOs) {
        query.search["device_os"] = search.deviceOs[0];
      }
    } else {
      delete query.search;
    }

    const queryDate = getQueryDate(filtersTb.date);

    if (queryDate) query.between = queryDate;

    if (filtersTb.state) {
      query["filter"] = {};

      if (filtersTb.state) {
        query["filter"]["state"] = filtersTb.state;
      }
    } else {
      delete query.filter;
    }

    REQUESTS.APP_RESET.GET({ query: JSON.stringify(query) })
      .then((data) => {
        setLoading(false);
        setDataSource(data.rows);
        setTotal(data.total);
        setLimit(data.limit);
        setCurrentPage(data.page);

        const pendingItems = data?.filter((item) => item.state === "pending");
        const pendingCount = pendingItems?.length;
        setPendingCount(pendingCount);

        if (data.rows.length === 0 && currentPage > 1) {
          setCurrentPage((prev) => prev - 1);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleMenuClick = (e, item, key) => {
    switch (key) {
      case "reset":
        confirmModal({
          title: translate("Do you want to reset this device"),
          okText: translate("Yes"),
          cancelText: translate("Cancel"),
          action: () => {
            REQUESTS.APP_RESET.POST({ id: item?.id })
              .then((res) => {
                message.success(res);
                getData();
                if (pendingCount > 0) {
                  setPendingCount((prev) => prev - 1);
                }
              })
              .catch((err) => {
                message.error(err);
              });
          },
        });
        break;
      case "cancel":
        confirmModal({
          title: translate("Do you want to cancel reset this device"),
          okText: translate("Yes"),
          cancelText: translate("Cancel"),
          action: () => {
            REQUESTS.APP_RESET.PUT({ id: item?.id })
              .then((res) => {
                message.success(res);
                getData();
                if (pendingCount > 0) {
                  setPendingCount((prev) => prev - 1);
                }
              })
              .catch((err) => {
                message.error(err);
              });
          },
        });
        break;
      default:
        break;
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setCurrentPage(pagination.current);

    setLimit(pagination.pageSize);

    setSortedInfo(sorter);

    setSearch((prevSearch) => ({
      ...prevSearch,
      deviceOs: filters["device_os"] || null,
      deviceKey: filters["device_key"] || null,
    }));

    setFiltersTb((prevFilters) => ({
      ...prevFilters,
      state: filters["state"] || null,
    }));

    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }
  };

  return (
    <PageComponent>
      <div className="head-page">
        <h3>{translate("Reset Requests")}</h3>
      </div>
      <Table
        rowKey="id"
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        onChange={handleTableChange}
        size="small"
        pagination={{
          position: ["bottomCenter"],
          current: currentPage,
          total: total,
          pageSize: limit,
          showSizeChanger: true,
        }}
        scroll={{
          x: "max-content",
          y: null,
        }}
      />
    </PageComponent>
  );
}

import React, { useState } from "react";
import VerificationInput from "react-verification-input";
import { Button } from "antd";

import useTranslate from "../../hooks/translator";

import { ButtonComponent } from "../../components";

export default function ConfirmationCode({
  setStep,
  setPercent,
  code,
  setCode,
}) {
  const translate = useTranslate();

  const [loading, setLoading] = useState(false);

  const onPrevious = () => {
    setPercent(0);
    setStep(0);
  };

  const getCode = () => {
    setLoading(true);
    setCode(code);
    setLoading(false);
    setStep(2);
    setPercent(0);
  };

  return (
    <>
      <div className="account-text-title">
        <h2>{translate("Confirmation code")}</h2>
        <p>
          {translate(
            "We have sent a verification code to your provided email. Please verify the confirmation code you have received in your email."
          )}
        </p>
      </div>

      <div className="account-form-wrap">
        <VerificationInput
          placeholder=""
          onComplete={(val) => setCode(val)}
          className="confirmation-code"
        />

        <div
          className="accounts-confirmation-btns"
          style={{ marginTop: "27px" }}
        >
          <Button onClick={() => onPrevious()} className="previous">
            {translate("Previous step")}
          </Button>

          <ButtonComponent
            type="primary"
            loading={loading}
            onClick={() => getCode()}
            disabled={!code}
          >
            {translate("Continue")}
          </ButtonComponent>
        </div>
      </div>
    </>
  );
}

import { ExclamationCircleOutlined } from "@ant-design/icons";
import DEVICE_INFO from "../../../videos/devices-info.mov";

export const deviceInfoPage = (translate) => {
  return (
    <div style={{ width: 500 }}>
      <p style={{ color: "#258ffb" }}>
        {" "}
        <ExclamationCircleOutlined /> {translate("Statistics")}
      </p>
      <p>
        {translate("Total: All devices count Free trial")} <br />
        <span style={{ color: "red" }}> {translate("WARNING")}: </span>{" "}
        {translate("For every 1 activation Expired: Devices whose")} <br />
      </p>
      <p style={{ color: "#258ffb" }}>
        {" "}
        <ExclamationCircleOutlined /> {translate("Action")}
      </p>
      <p>
        {translate("By clicking on menu you can")} <br />
        <span style={{ color: "red" }}> {translate("WARNING")}: </span>{" "}
        {translate(
          "If you want to activate the device after deactivating it, you must spend 1 credit from your account"
        )}
      </p>
    </div>
  );
};

export const deviceInfoModal = (translate) => {
  return (
    <div className="add-device-info">
      <p>
        1. {translate("Please enter the Device key")}
        <br />
        2. {translate("You can choose: Give the device free trial")}
        <br />
        <span style={{ color: "red" }}> {translate("WARNING")}: </span>{" "}
        {translate("activation info")} <br />
        3. {translate("Select and attach one from your server")} <br />
        4. {translate("You can click on checkbox and let our system")}
        <br />
        <span style={{ color: "red" }}> {translate("WARNING")}: </span>{" "}
        {translate(
          "For every 1 activation will be charged 1 credit from your account"
        )}{" "}
      </p>
      <p>
        {translate(
          "With save button you can save your changes and the new device will be added successfully"
        )}
      </p>
      <video width="100%" height="240" controls style={{ marginTop: "-30px" }}>
        <source src={DEVICE_INFO} type="video/quicktime" />
        <source src={DEVICE_INFO} type="video/mp4" />
      </video>
    </div>
  );
};

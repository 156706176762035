import { ICONS, IMAGES } from "../../../config";

function Package({ item, isSelected, onSelect }) {
  return (
    <div
      key={item.id}
      className={`package-container ${isSelected ? "selected-package" : ""}`}
    >
      <div className={`package`} onClick={onSelect}>
        <div className="background">
          <img src={IMAGES.SUBTRACT} alt="background" />
        </div>
        <div className={`package-header`}>
          <div
            className="trending-package "
            style={{
              background: !item?.is_popular && "transparent",
            }}
          >
            {item?.is_popular && "Most Popular"}
          </div>

          <div className="circle">
            {isSelected && <div className="selected-circle" />}
          </div>
        </div>

        <div className="package-body">
          <div className="package-title-block">
            <div className="package-title-block-wrap">
              <div className="package-title">{item?.name}</div>
              {item?.discount && <div className="sale">{item?.discount}</div>}
            </div>
          </div>
          <div className="old_price">
            <del>{item?.old_price && `$${item?.old_price}`}</del>
          </div>
          <div className="price_dollar">${item?.price}</div>
          <div className="price_br">
            R${item?.brl_price}{" "}
            {item?.old_brl_price && <del>R${item?.old_brl_price}</del>}
          </div>
        </div>

        <div className="package-credit">
          <div className="package_credit_body">
            <span className="package-icon">{ICONS.TRUE}</span>
            <span>Credit {item?.credit}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Package;

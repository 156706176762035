import { useState, useEffect } from "react";

import { Button } from "antd";

import { SyncOutlined } from "@ant-design/icons";

import REQUESTS from "../../api/requests";

import useTranslate from "../../hooks/translator";

const DeviceCount = ({ serverId, disabled, reset }) => {
  const [count, setCount] = useState(0);

  const [status, setStatus] = useState("default"); // default, processing, finish, "error"

  const translate = useTranslate();

  useEffect(() => {
    if (reset) {
      setCount(0);
      setStatus("default");
    }
  }, [reset]);

  const getDeviceCount = () => {
    REQUESTS.SERVER_HOSTS.GET_DEVICES_COUNT({
      server_id: serverId,
    })
      .then((data) => {
        setCount(data);

        setStatus("finish");
      })
      .catch((err) => {
        setStatus("error");
      });
  };

  const onClick = () => {
    setStatus("processing");
    getDeviceCount();
  };

  const renderComponent = () => {
    switch (status) {
      case "default":
        return (
          <Button
            type="link"
            size="small"
            onClick={onClick}
            disabled={disabled}
          >
            {translate("show")}
          </Button>
        );
      case "processing":
        return (
          <Button
            size="small"
            type="link"
            disabled={disabled}
            icon={<SyncOutlined spin />}
          >
            {translate("please wait")}
          </Button>
        );
      case "finish":
        return (
          <Button
            size="small"
            type="link"
            disabled={disabled}
            onClick={onClick}
            style={{ color: disabled ? "#00000040" : "black" }}
          >
            {count}
          </Button>
        );

      case "error":
        return (
          <Button
            size="small"
            type="link"
            style={{ color: "#c74f51" }}
            onClick={onClick}
          >
            {translate("try later")}
          </Button>
        );
      default:
        return null;
    }
  };

  return renderComponent();
};

export default DeviceCount;

import { useContext } from "react";

import { BrandingContext } from "../BrandingContext";

import { menuBrandingIconsCollection } from "../../../config/data";
import { returnColor } from "../../../config/utils";
import IMAGES from "../../../config/images";

export default function MenuRmsButtons() {
  const {
    menuIconsCollection,
    menuIconsColor,
    menuItemsActiveColor,
    menuItemsBoxForm,
  } = useContext(BrandingContext);

  const svgBackground = `<svg xmlns="http://www.w3.org/2000/svg" width="329" height="330" viewBox="0 0 329 330" fill="none" class="uKPnuVBNJXrkYBle9TvA"><g filter="url(#filter0_f_4913_9480)"><circle cx="164.673" cy="164.859" r="94.3848" transform="rotate(-90 164.673 164.859)" fill="url(#paint0_linear_4913_9480)"></circle></g><defs><filter id="filter0_f_4913_9480" x="0.373405" y="0.559929" width="328.599" height="328.599" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood><feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend><feGaussianBlur stdDeviation="34.9573" result="effect1_foregroundBlur_4913_9480"></feGaussianBlur></filter><linearGradient id="paint0_linear_4913_9480" x1="164.673" y1="70.4745" x2="164.673" y2="259.244" gradientUnits="userSpaceOnUse"><stop stop-color= "${
    menuItemsActiveColor ? returnColor(menuItemsActiveColor, 20) : "#e50dNaN"
  }"></stop><stop offset="0" stop-color= "${
    menuItemsActiveColor ? returnColor(menuItemsActiveColor, 10) : "#ff0fNaN"
  }" ></stop></linearGradient></defs></svg>`;

  const returnMenuItemsBoxForm = () => {
    switch (menuItemsBoxForm) {
      case "round":
        return "50%";
      case "quadrate":
        return "0px";
      case "halfcircle":
        return "15px";
      default:
        return "50%";
    }
  };

  const returnMenuItemsBoxBG = () => {
    switch (menuItemsBoxForm) {
      case "round":
        return IMAGES.ROUND;
      case "quadrate":
        return IMAGES.QUADRATE;
      case "halfcircle":
        return IMAGES.HALFCIRCLE;
      default:
        return IMAGES.ROUND;
    }
  };

  return (
    <div className="rms-menu-items">
      {menuBrandingIconsCollection[menuIconsCollection - 1] ? (
        Object?.entries(
          menuBrandingIconsCollection[menuIconsCollection - 1]
        )?.map(([key, value]) => (
          <div className="rms-items-wrap" key={key}>
            <div
              className="rms-item"
              style={{
                backgroundImage: `url(${returnMenuItemsBoxBG()})`,

                color: menuIconsColor,

                backgroundColor:
                  key == "live"
                    ? returnColor(menuItemsActiveColor, 10)
                    : "transparent",

                boxShadow:
                  key == "live"
                    ? `0.49px 0.49px 20.97px 0px ${
                        menuItemsActiveColor || "#27282a inset"
                      }`
                    : `0px 6.99px 6.99px 0px #27282a inset`,

                borderRadius: returnMenuItemsBoxForm(),
                transform: `${key == "live" ? "scale(1.1)" : "none"}`,
              }}
            >
              {key == "live" && (
                <img
                  src={`data:image/svg+xml,${encodeURIComponent(
                    svgBackground
                  )}`}
                  className="light-effect"
                />
              )}
              {value}
            </div>

            <p
              className="rms-item-title"
              style={{
                color: menuIconsColor,
              }}
            >
              {key == "live" ? "LIVE TV" : key}
            </p>
          </div>
        ))
      ) : (
        <></>
      )}
    </div>
  );
}

import { InfoPopover } from "../../components";
import useTranslate from "../../hooks/translator";
import MIGRATION_INFO from "../../videos/migration-info.mov";

export default function MigrationInfoContent() {
  const translate = useTranslate();

  return (
    <InfoPopover
      content={
        <div style={{ width: 300 }}>
          <p>
            {translate("Migration system allows you to move all")}
            <br />
            {translate("server migration info")} <br />
            {translate(
              "You have to wait until migration will be ended successfully"
            )}
            <br />
            <span style={{ color: "red" }}> {translate("WARNING")}: </span>{" "}
            {translate(
              "It can take from few minutes to hours depending on the device count"
            )}
            <br />
            {translate(
              "You will get email message about the results of the migration in the end of the process"
            )}
          </p>
          <video width="100%" height="auto" controls>
            <source src={MIGRATION_INFO} type="video/quicktime" />
            <source src={MIGRATION_INFO} type="video/mp4" />
          </video>
        </div>
      }
      title={translate("Migration system")}
    />
  );
}

import { useState } from "react";

import { useSearchParams, useNavigate } from "react-router-dom";

import { Form } from "antd";

import REQUESTS from "../../api/requests";
import useTranslate from "../../hooks/translator";
import { PATHS, ICONS } from "../../config";
import { themesForProvider, APP_FOR } from "../../config/themesConfigs";

import {
  InputComponent,
  ButtonComponent,
  MessageComponent
} from "../../components";

import AuthorizationPage from "./AuthorizationPage";

/**
 * Reset Password Page is a page for users to reset their password, if they forgot it. It is a form with email input and a submit button.
 * We use the Form component from Ant Design to create the form. We use the InputComponent and ButtonComponent components to create the
 * input and the button. We use the AuthorizationPage component to create the page. We use the MessageComponent component to show the
 * message to the user. We use the useSearchParams hook to get the code from the URL. We use the useNavigate hook to navigate to the
 * login page. We use the REQUESTS.RESET_PASSWORD function to send the request to the server.
 * @returns
 */

export default function ResetPasswordPage() {
  const translate = useTranslate();

  const navigate = useNavigate(); // We use the useNavigate hook to navigate to the login page.

  const [searchParams] = useSearchParams(); // We use the useSearchParams hook to get the code from the URL.
  const [loading, setLoading] = useState(false); // When the request is being processed, it will be true. Otherwise, it will be false.
  const [errorMessage, setErrorMessage] = useState(""); // Message to show to the user after the request is done. It can be an error or a success message.

  const [form] = Form.useForm(); // We use the Form.useForm hook to create the form. We use the form to get the values of the inputs.

  const onFinish = (values) => {
    // When the form is submitted, this function will be called.
    setLoading(true);
    setErrorMessage("");

    REQUESTS.RESET_PASSWORD({ ...values, code: searchParams.get("code") })
      .then((data) => {
        navigate(PATHS.LOGIN);
        setLoading(false);
      })
      .catch((err) => {
        if (typeof err === "string") {
          setErrorMessage(err);
        } else {
          setErrorMessage(err.message);
        }
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    // When the form is submitted and there is an error, this function will be called.
    setLoading(false);
  };

  const onValuesChange = () => {
    // When the values of the inputs change, this function will be called. It will clear the error message.
    setErrorMessage("");
  };

  return (
    <AuthorizationPage
      title={translate("Reset password")}
      primaryColor={themesForProvider[APP_FOR].primaryColor}
      logo={themesForProvider[APP_FOR].logo}
      logoSize={themesForProvider[APP_FOR]?.logo_size}
    >
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
        onValuesChange={onValuesChange}
      >
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: translate("Please input your password")
            },
            {
              min: 8,
              message: translate("Password must be minimum 8 characters")
            }
          ]}
        >
          <InputComponent
            type="password"
            prefix={ICONS.PASSWORD}
            placeholder={translate("Password")}
          />
        </Form.Item>

        <Form.Item
          name="confirmPassword"
          dependencies={["password"]}
          rules={[
            {
              required: true,
              message: translate("Please confirm your password")
            },

            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }

                return Promise.reject(
                  new Error(
                    translate("The two passwords that you entered do not match")
                  )
                );
              }
            })
          ]}
        >
          <InputComponent
            type="password"
            prefix={ICONS.PASSWORD}
            placeholder={translate("Retype Password")}
          />
        </Form.Item>
        <MessageComponent message={errorMessage} />
        <Form.Item>
          <ButtonComponent block htmlType="submit" loading={loading}>
            {translate("Reset my password")}
          </ButtonComponent>
        </Form.Item>
      </Form>
    </AuthorizationPage>
  );
}

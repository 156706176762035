import React, { createContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const BrandingContext = createContext();

export const BrandingProvider = ({ children }) => {
  const { appBranding } = useSelector((store) => store.profile);

  const [backgroundImage, setBackgroundImage] = useState(null);
  const [logo, setLogo] = useState(null);
  const [logoSize, setLogoSize] = useState(null);
  const [menuIconsCollection, setMenuIconsCollection] = useState(null);
  const [menuIconsColor, setMenuIconsColor] = useState("");
  const [menuItemsActiveColor, setMenuItemsActiveColor] = useState(null);
  const [menuItemsBoxForm, setMenuItemsBoxForm] = useState("");
  const [menuItemsColor, setMenuItemsColor] = useState("");
  const [menuOption, setMenuOption] = useState("");
  const [playerIconsCollection, setPlayerIconsCollection] = useState(null);
  const [playerItemsColor, setPlayerItemsColor] = useState("");
  const [playerVersion, setPlayerVersion] = useState(null);
  const [settingsVersion, setSettingsVersion] = useState("");
  const [primaryColor, setPrimaryColor] = useState("");
  const [progressBarColor, setProgressBarColor] = useState(null);
  const [secondaryColor, setSecondaryColor] = useState("");

  useEffect(() => {
    if (appBranding) {
      setBackgroundImage(appBranding.background_image);
      setLogo(appBranding.logo);
      setLogoSize(appBranding.logo_size);
      setMenuIconsCollection(appBranding.menu_icons_collection);
      setMenuIconsColor(appBranding.menu_icons_color || "");
      setMenuItemsActiveColor(appBranding.menu_items_active_color || "");
      setMenuItemsBoxForm(appBranding.menu_items_box_form);
      setMenuItemsColor(appBranding.menu_items_color || "");
      setMenuOption(appBranding.menu_option);
      setPlayerIconsCollection(appBranding.player_icons_collection || 1);
      setPlayerItemsColor(appBranding.player_items_color || "");
      setPlayerVersion(appBranding.player_version || 1);
      setSettingsVersion(appBranding.settings_version || "3");
      setPrimaryColor(appBranding.primary_color || "");
      setProgressBarColor(appBranding.progress_bar_color || "");
      setSecondaryColor(appBranding.secondary_color || "");
    }
  }, [appBranding]);

  const contextValue = {
    backgroundImage,
    setBackgroundImage,
    logo,
    setLogo,
    logoSize,
    setLogoSize,
    menuIconsCollection,
    setMenuIconsCollection,
    menuIconsColor,
    setMenuIconsColor,
    menuItemsColor,
    setMenuItemsColor,
    menuItemsActiveColor,
    setMenuItemsActiveColor,
    menuItemsBoxForm,
    setMenuItemsBoxForm,
    menuOption,
    setMenuOption,
    playerIconsCollection,
    setPlayerIconsCollection,
    playerItemsColor,
    setPlayerItemsColor,
    playerVersion,
    setPlayerVersion,
    settingsVersion,
    setSettingsVersion,
    primaryColor,
    setPrimaryColor,
    progressBarColor,
    setProgressBarColor,
    secondaryColor,
    setSecondaryColor,
  };

  return (
    <BrandingContext.Provider value={contextValue}>
      {children}
    </BrandingContext.Provider>
  );
};

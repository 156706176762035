import React, { useContext } from "react";
import { Radio } from "antd";
import { BrandingContext } from "../BrandingContext";
import useTranslate from "../../../hooks/translator";
import SettingsVersion from "../components/SettingsVersion";
import "../styles/settings.scss";
import { IMAGES } from "../../../config";

export default function Settings() {
  const { settingsVersion, setSettingsVersion } = useContext(BrandingContext);
  const translate = useTranslate();

  const returnSettingsPlayer = () => {
    switch (settingsVersion) {
      case "2":
        return <SettingsVersion path={IMAGES.BRANDING_SETTINGSV2} />;
      case "3":
        return <SettingsVersion path={IMAGES.BRANDING_SETTINGSV3} />;
      default:
        return <SettingsVersion path={IMAGES.BRANDING_SETTINGSV1} />;
    }
  };

  return (
    <div className="page-settings">
      <div className="collections-list">
        <div>
          <p className="panel-title">{translate("Choose settings")}</p>
          <Radio.Group
            value={settingsVersion}
            className="radio-group"
            onChange={(e) => setSettingsVersion(e.target.value)}
          >
            <Radio value={"1"}>{translate("Settings")} 1</Radio>
            <Radio value={"2"}>{translate("Settings")} 2</Radio>
            <Radio value={"3"}>{translate("Settings")} 3</Radio>
          </Radio.Group>
        </div>
      </div>
      <div>{returnSettingsPlayer()}</div>
    </div>
  );
}

import React, { useState, useEffect, memo } from "react";
import { Pie } from "@ant-design/plots";
import { Modal, Empty } from "antd";
import REQUESTS from "../../../api/requests";
import useTranslate from "../../../hooks/translator";

export default function ViewDevicesTotalInfo({ open, onCancel }) {
  const translate = useTranslate();

  const [data, setData] = useState(null);

  useEffect(() => {
    REQUESTS.DEVICES_TOTAL_INFO()
      .then((data) => {
        if (data) {
          setData(data);
        }
      })
      .catch(() => {});
  }, []);

  const chartData = [
    { type: "Active", value: data?.active },
    { type: "Free trial", value: data?.free_trial },
    { type: "Trial expired", value: data?.trial_expired },
    { type: "Expired", value: data?.expired },
  ];

  const config = {
    appendPadding: 10,
    data: chartData,
    angleField: "value",
    colorField: "type",
    radius: 0.9,
    label: {
      text: "value",
      position: "outside",
    },
    interactions: [
      {
        type: "element-active",
      },
    ],
    style: {
      minHeight: 250,
    },
  };

  if (!chartData) return <div></div>;

  return (
    <Modal
      title={translate("Show Statistics")}
      open={open}
      onCancel={onCancel}
      footer={null}
    >
      {chartData.length > 0 ? <Pie {...config} /> : <Empty />}
    </Modal>
  );
}

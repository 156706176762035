import XCLOUD_LOGO from "../images/pms-for/xcloud.png";
import SELLING_LOGO from "../images/pms-for/selling-team-logo.png";
import UNIPLAY_LOGO from "../images/pms-for/uniplay_logo.png";
import TVS_LOGO from "../images/pms-for/tvs-logo.png";
import WAPP_LOGO from "../images/pms-for/wapp-tv-logo.png";

export const themesForProvider = {
  xcloud: {
    appName: "Xtream Cloud",
    primaryColor: "#173E4B",
    btnColor: "#173E4B",
    secondaryColor: "#132d36",
    activeColor: "#132d36",
    profileBtn: "#74878d",
    menuItemColor: "#979797",
    menuActiveItemColor: "#25f662",
    tbColor: "#b8b8b8",
    cardColor: "#141638",
    logo: XCLOUD_LOGO,
    logo_size: 4,
  },
  selling: {
    appName: "Selling Team",
    primaryColor: "#161719",
    btnColor: "#161719",
    secondaryColor: "#333333",
    activeColor: "#eb6263",
    profileBtn: "#74878d",
    menuItemColor: "#979797",
    menuActiveItemColor: "#fff",
    tbColor: "#f5f5f5",
    cardColor: "#141638",
    logo: SELLING_LOGO,
    logo_size: 7,
  },
  uniplay: {
    appName: "UNIPLAY",
    primaryColor: "#011b4c",
    btnColor: "#00153a",
    secondaryColor: "#001538",
    activeColor: "#5a9df0",
    profileBtn: "#5a9df0",
    menuItemColor: "#f5f5f5",
    menuActiveItemColor: "#5a9df0",
    tbColor: "#b8b8b8",
    cardColor: "#141638",
    logo: UNIPLAY_LOGO,
    logo_size: 12,
  },
  tvs: {
    appName: "TVS",
    primaryColor: "#131638",
    btnColor: "#131638",
    secondaryColor: "#0c0e2cb5",
    activeColor: "#5a9df0",
    profileBtn: "#5a9df0",
    menuItemColor: "#f5f5f5",
    menuActiveItemColor: "#d6b4e7",
    tbColor: "#b8b8b8",
    cardColor: "#6d4786f7",
    logo: TVS_LOGO,
    logo_size: 7,
  },
  wapp: {
    appName: "WAPP",
    primaryColor: "#06111c",
    btnColor: "#06111c",
    secondaryColor: "#0c1a27",
    activeColor: "#5a9df0",
    profileBtn: "#5a9df0",
    menuItemColor: "#f5f5f5",
    menuActiveItemColor: "#01af5d",
    tbColor: "#b8b8b8",
    cardColor: "#141638",
    logo: WAPP_LOGO,
    logo_size: 12,
  },
};

export const APP_FOR = themesForProvider?.hasOwnProperty(
  process.env.REACT_APP_FOR
)
  ? process.env.REACT_APP_FOR
  : "xcloud";

export const modifyClassStyle = () => {
  const root = document.documentElement;
  const theme = themesForProvider[APP_FOR];
  root.style.setProperty("--menu-item-color", theme?.menuItemColor);
  root.style.setProperty(
    "--menu-active-item-color",
    theme?.menuActiveItemColor
  );
  root.style.setProperty("--btn-color", theme?.btnColor);
  root.style.setProperty("--tb-color", theme?.tbColor);
};

import { useEffect, useState } from "react";
import { Button, Form, Drawer, message } from "antd";

import { useSelector, useDispatch } from "react-redux";

import REQUESTS from "../api/requests";

import useTranslate from "../hooks/translator";

import { setProfile } from "../store/features/profileSlice";

import {
  ButtonComponent,
  InputComponent,
  MessageComponent
} from "../components";

import ResetProfilePasswordDrawer from "./ResetProfilePasswordDrawer";

export default function EditProfileDrawer({ open, onClose }) {
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const translate = useTranslate();

  const { profile } = useSelector((state) => state.profile);

  const [loading, setLoading] = useState(false);

  const [messageErr, setMessageErr] = useState(null);

  const [isOpenResetPasswordDrawer, setIsOpenResetPasswordDrawer] =
    useState(false);

  const getProfile = () => {
    REQUESTS.PROFILE()
      .then((data) => {
        dispatch(setProfile(data));
      })
      .catch((err) => {});
  };

  const onFinish = (values) => {
    if (!open) return;

    setLoading(true);

    setMessageErr("");

    const body = {
      name: values.name,
      surname: values.surname,
      phone_number: values.phone_number
    };

    REQUESTS.EDIT_PROFILE(body)
      .then((data) => {
        if (data) {
          message.success(data);

          setLoading(false);

          onClose();
          getProfile();
        }
      })
      .catch((err) => {
        if (typeof err === "string") {
          setMessageErr(err);
        }
        if (typeof err.message === "string") {
          setMessageErr(err.message);
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    if (open) {
      form.resetFields();

      if (profile?.is_partner) {
        form.setFields([
          {
            name: "partner_key",
            value: profile?.partner_key
          }
        ]);
      }

      form.setFields([
        {
          name: "brand_name",
          value: profile?.brand_name
        },
        {
          name: "name",
          value: profile?.name
        },
        {
          name: "surname",
          value: profile?.surname
        },
        {
          name: "email",
          value: profile?.email
        },
        {
          name: "phone_number",
          value: profile?.phone_number
        }
      ]);
    } else {
      setMessageErr("");
    }
  }, [open]);

  return (
    <Drawer
      open={open}
      placement="right"
      onClose={onClose}
      title={translate("Edit profile")}
    >
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        onValuesChange={() => setMessageErr("")}
      >
        <Form.Item
          label={translate("Brand name")}
          name="brand_name"
          extra={translate("Please, contact us in case of any changes")}
        >
          <InputComponent disabled />
        </Form.Item>
        {profile?.is_partner && (
          <Form.Item label={translate("Partner key")} name="partner_key">
            <InputComponent
              disabled={true}
              placeholder={translate("Partner key")}
            />
          </Form.Item>
        )}

        <Form.Item
          name="name"
          label={translate("Name")}
          rules={[
            {
              required: true,
              message: translate("Please input your name")
            }
          ]}
        >
          <InputComponent />
        </Form.Item>

        <Form.Item
          name="surname"
          label={translate("Surname")}
          rules={[
            {
              required: true,
              message: translate("Please input your surname")
            }
          ]}
        >
          <InputComponent />
        </Form.Item>

        <Form.Item label={translate("Email")} name="email">
          <InputComponent disabled={true} />
        </Form.Item>

        <Form.Item label={translate("Phone")} name="phone_number">
          <InputComponent />
        </Form.Item>

        <Button
          type="link"
          onClick={setIsOpenResetPasswordDrawer}
          style={{ padding: 0 }}
        >
          {translate("Reset password")}
        </Button>
        <MessageComponent message={messageErr} />

        <Form.Item>
          <ButtonComponent
            type="primary"
            htmlType="submit"
            style={{ marginTop: 20, width: "100%" }}
            loading={loading}
          >
            {translate("Save")}
          </ButtonComponent>
        </Form.Item>
      </Form>

      <ResetProfilePasswordDrawer
        open={isOpenResetPasswordDrawer}
        onClose={() => setIsOpenResetPasswordDrawer(false)}
      />
    </Drawer>
  );
}

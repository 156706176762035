import APK_FILE from "../files/x-cloud.apk";
import ANDROID_MOBILE_FILE from "../files/android-mobile.apk";
import USER_WIDGET_ZIP from "../files/userwidget.zip";

const FILES = {
  APK_FILE,
  ANDROID_MOBILE_FILE,
  USER_WIDGET_ZIP
};

export default FILES;

import React, { useContext } from "react";
import { useSelector } from "react-redux";

import { Radio } from "antd";

import { BrandingContext } from "../BrandingContext";

import {
  menuBrandingIconsCollection,
  menuClassicIconsCollection,
  menuV3MenuIconsCollection,
} from "../../../config/data";

import useTranslate from "../../../hooks/translator";

import { ColorsPicker } from "../../../components";

import MenuRms from "../components/MenuRms";
import MenuClassic from "../components/MenuClassic";
import MenuV3 from "../components/MenuV3";

import "../styles/menu.scss";

export default function MenuApp() {
  const {
    menuOption,
    setMenuOption,
    menuIconsCollection,
    setMenuIconsCollection,
    menuItemsBoxForm,
    setMenuItemsBoxForm,
    menuIconsColor,
    setMenuIconsColor,
    menuItemsColor,
    setMenuItemsColor,
    menuItemsActiveColor,
    setMenuItemsActiveColor,
  } = useContext(BrandingContext);

  const { appBranding } = useSelector((store) => store.profile);

  const translate = useTranslate();

  const returnCurrentMenu = () => {
    switch (menuOption) {
      case "menu_v3":
        return <MenuV3 />;
      case "classic":
        return <MenuClassic />;
      default:
        return <MenuRms />;
    }
  };

  const returnMenuIconsCollectionArr = () => {
    switch (menuOption) {
      case "menu_v3":
        return menuV3MenuIconsCollection;
      case "classic":
        return menuClassicIconsCollection;
      default:
        return menuBrandingIconsCollection;
    }
  };

  return (
    <div className="menu-page">
      <div className="choose-wrap collections-list">
        <div>
          <p className="panel-title">{translate("Choose menu")}</p>
          <Radio.Group
            value={menuOption}
            className="radio-group"
            onChange={(e) => setMenuOption(e.target.value)}
          >
            <Radio value="rms">{translate("Version")} 1</Radio>
            <Radio value="classic">{translate("Version")} 2</Radio>
            <Radio value="menu_v3">{translate("Version")} 3</Radio>
          </Radio.Group>
        </div>
        <div>
          <p className="panel-title">{translate("Choose items box form")} </p>
          <Radio.Group
            value={menuItemsBoxForm}
            className="radio-group"
            onChange={(e) => setMenuItemsBoxForm(e.target.value)}
          >
            <Radio value="round">Round</Radio>
            <Radio value="quadrate">Quadrate</Radio>
            <Radio value="halfcircle">Halfcircle</Radio>
          </Radio.Group>
        </div>

        <div>
          <p className="panel-title">{translate("Choose icons color")} </p>
          <ColorsPicker
            color={menuIconsColor}
            onChange={(color) =>
              setMenuIconsColor(
                color ? color.hex : appBranding.menu_icons_color
              )
            }
          />
        </div>

        {menuOption !== "rms" && (
          <div>
            <p className="panel-title">{translate("Choose items color")} </p>
            <ColorsPicker
              color={menuItemsColor}
              onChange={(color) =>
                setMenuItemsColor(
                  color ? color.hex : appBranding.menu_items_color
                )
              }
            />
          </div>
        )}
        <div>
          <p className="panel-title">
            {translate("Choose items active color")}{" "}
          </p>
          <ColorsPicker
            color={menuItemsActiveColor}
            onChange={(color) => {
              setMenuItemsActiveColor(
                color ? color.hex : appBranding.menu_items_active_color
              );
            }}
          />
        </div>
        <div className="choose-list">
          <p className="panel-title">{translate("Choose your icons")}</p>
          <Radio.Group
            value={menuIconsCollection}
            onChange={(e) => setMenuIconsCollection(e.target.value)}
            className="radio-group"
          >
            {returnMenuIconsCollectionArr().map((item, index) => (
              <Radio
                value={index + 1}
                className="radio-collections"
                key={index}
              >
                <p>
                  {translate("Collection")} {index + 1}
                </p>

                <div className="collection">
                  {item.live}
                  {item.movies}
                  {item.series}
                  {item.settings}
                </div>
              </Radio>
            ))}
          </Radio.Group>
        </div>
      </div>
      <div className="menu-wrap">{returnCurrentMenu()}</div>
    </div>
  );
}

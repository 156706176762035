import { Dropdown, Space, Button } from "antd";
import React from "react";

import ICONS from "../config/icons";

export default function ActionTable({
  handleMenuClick = function () {},
  buttons = [],
}) {
  const items = buttons
    ?.filter((item) => Boolean(item))
    .map((item) => ({
      label: item.label,
      key: item.key,
      icon: item.icon,
      onClick: handleMenuClick,
      disabled: item.disabled,
    }));

  return (
    <Dropdown
      trigger={["click"]}
      className="hover-dropdown"
      style={{ minWidth: 160, padding: 0 }}
      menu={{
        items,
      }}
    >
      <Button
        onClick={(e) => e.preventDefault()}
        className="action-btn"
        style={{ width: 50 }}
        type="link"
      >
        <Space>{ICONS.ACTION_TB}</Space>
      </Button>
    </Dropdown>
  );
}

import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { useSelector } from "react-redux";

import { Helmet } from "react-helmet";

import { Drawer, Layout, Spin } from "antd";

import useTranslate from "../hooks/translator";

import { themesForProvider, APP_FOR } from "../config/themesConfigs";
import ICONS from "../config/icons";

import Sidebar from "./Sidebar";
import Header from "./Header";
import MenuContent from "./MenuContent";

const { Content } = Layout;

export default function LayoutComponent() {
  const { profile } = useSelector((store) => store.profile);

  const location = useLocation();
  const translate = useTranslate();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [open, setOpen] = useState(false);

  const isMobile = windowWidth <= 800;

  useEffect(() => {
    const windowSize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", windowSize);

    return () => window.removeEventListener("resize", windowSize);
  }, []);

  useEffect(() => {
    const updateBodyOverflow = () => {
      document.documentElement.style.overflow = open ? "hidden" : "";
      document.body.style.overflow = open ? "hidden" : "";
    };

    updateBodyOverflow();

    // Clean up the styles when the component unmounts or openMobile changes
    return () => {
      document.documentElement.style.overflow = "";
      document.body.style.overflow = "";
    };
  }, [open]);

  return (
    <>
      <Helmet>
        <title>{`${translate(
          `${themesForProvider[APP_FOR]?.appName} Admin Panel`
        )} | ${location.pathname?.substring(1)} | ${profile?.name} | ${
          profile?.name
        } | ${profile?.id}`}</title>
      </Helmet>

      <Layout style={{ overflow: !profile ? "hidden" : "auto" }}>
        {!isMobile ? (
          <Sidebar isMobile={isMobile} />
        ) : (
          <Drawer
            placement="left"
            open={open}
            closable={false}
            maskClosable={true}
            closeIcon={false}
            onClose={() => setOpen(false)}
            title={false}
            width={300}
            bodyStyle={{
              padding: 0,
              background: themesForProvider[APP_FOR]?.primaryColor,
            }}
            getContainer={() => document.getElementById("root")}
          >
            <div className="sidebar" style={{ height: "100%", margin: 0 }}>
              <MenuContent onClose={() => setOpen(false)} isMobile={isMobile} />
              <div className="close-icon" onClick={() => setOpen(false)}>
                {ICONS.CLOSE}
              </div>
            </div>
          </Drawer>
        )}
        <Layout
          style={{
            overflowY: "hidden",
            transition: "all 0.2s",
            marginLeft: isMobile ? 0 : "270px",
          }}
        >
          <Header isMobile={isMobile} setOpenMenuDrawer={setOpen} />

          <Content className="site-layout-background">
            <div className="outlet-content">
              <Outlet />
            </div>
          </Content>
        </Layout>
        {!profile && (
          <div className="page-spin">
            <Spin spinning={true} fullscreen="true" size="large" />
          </div>
        )}
      </Layout>
    </>
  );
}

import { useEffect, useState } from "react";

import { Drawer, Form, Result, Select, Spin } from "antd";

import REQUESTS from "../../api/requests";

import useTranslate from "../../hooks/translator";

import { useDnsOptions } from "../../hooks/useDnsOptions";

import { ButtonComponent } from "../../components";

import MigrationInfoContent from "./MigrationInfoContent";

import resultModal from "../../components/result-modal/resultModal";

export default function MigrationDrawer({
  selected,
  onClose,
  getData,
  servers
}) {
  const translate = useTranslate();
  const toServerList = useDnsOptions(servers);

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const filterArr = toServerList?.filter((el) => el.id !== selected?.item?.id);

  const [deviceCount, setDeviceCount] = useState(0);

  const [getDeviceCountStatus, setGetDeviceCountStatus] =
    useState("processing");
  // processing, finish, "error";

  const getDeviceCount = () => {
    setGetDeviceCountStatus("processing");

    REQUESTS.SERVER_HOSTS.GET_DEVICES_COUNT({
      server_id: selected?.item?.id
    })
      .then((data) => {
        setDeviceCount(data);

        setGetDeviceCountStatus("finish");
      })
      .catch((err) => {
        setGetDeviceCountStatus("error");
      });
  };

  useEffect(() => {
    if (selected && selected?.item?.id) {
      getDeviceCount();
    }
  }, [selected]);

  const onFinish = (values) => {
    setLoading(true);

    const body = {
      from: selected?.item?.id,
      to: values.to
    };

    REQUESTS.MIGRATION.PUT(body)
      .then((res) => {
        setLoading(false);
        resultModal({ type: "Success", response: translate("migration_text") });
        getData();
        onClose();
      })
      .catch((error) => {
        let errorText = typeof error === "string" ? error : error.message;

        setLoading(false);

        resultModal({ type: "Error", response: errorText || "" });
      });
  };

  const renderComponent = () => {
    switch (getDeviceCountStatus) {
      case "processing":
        return (
          <div
            style={{
              width: "100%",
              height: 200,
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Spin />
          </div>
        );

      case "finish":
        if (deviceCount === 0) {
          return (
            <Result
              status="warning"
              subTitle={translate("This server has no devices")}
            />
          );
        } else
          return (
            <Form
              layout="vertical"
              form={form}
              onFinish={onFinish}
              style={{ width: "100%" }}
            >
              <Form.Item
                label={translate("Pick new server to migrate your users")}
                name="to"
                rules={[
                  {
                    required: true,
                    message: translate("Select DNS")
                  }
                ]}
              >
                <Select
                  options={filterArr}
                  placeholder={translate("Select DNS")}
                />
              </Form.Item>

              <Form.Item>
                <ButtonComponent loading={loading} onClick={form.submit}>
                  <span>{translate("Save")}</span>
                </ButtonComponent>
              </Form.Item>
            </Form>
          );

      case "error":
        return (
          <Result status="error" subTitle={translate("Something went wrong")} />
        );

      default:
        return null;
    }
  };

  return (
    <Drawer
      forceRender
      open={selected?.open}
      onClose={onClose}
      placement="right"
      title={
        <>
          {translate("Migration")}
          <MigrationInfoContent />
        </>
      }
    >
      <h2 style={{ fontSize: "14px", color: "gray" }}>
        {`${translate("Migrate your users from")} ${selected?.item?.host}`}{" "}
        {getDeviceCountStatus === "finish" &&
          deviceCount > 0 &&
          `${translate("Device count")}: ${deviceCount} `}
      </h2>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        {renderComponent()}
      </div>
    </Drawer>
  );
}

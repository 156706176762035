import { useEffect, useState } from "react";
import { Input, Form, Divider, Switch, message } from "antd";

import REQUESTS from "../../api/requests";

import useTranslate from "../../hooks/translator";

import { ButtonComponent } from "../../components";

export default function ContactsPage() {
  const translate = useTranslate();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(true);
  const [currentData, setCurrentData] = useState(null);

  useEffect(() => {
    getCurrentData();
  }, []);

  const getCurrentData = () => {
    REQUESTS.SETTINGS_CONTACTS.GET({})
      .then((data) => {
        setCurrentData(data);
        if (data) {
          form.setFields([
            { name: "is_visibale", value: data.is_visibale },
            { name: "phone", value: data.phone },
            { name: "email", value: data.email },
            { name: "web_page", value: data.web_page },
            { name: "whatsapp", value: data.whatsapp },
            { name: "telegram", value: data.telegram },
            { name: "facebook", value: data.facebook },
            { name: "tiktok", value: data.tiktok },
            { name: "join_telegram_group", value: data.join_telegram_group },
            { name: "join_whatsapp_group", value: data.join_whatsapp_group },
            { name: "notes", value: data.notes },
          ]);
        }
      })
      .catch(() => {});
  };

  const onFinish = async (values) => {
    if (loading) return;

    setLoading(true);

    const body = Object.keys(values)?.reduce((acc, key) => {
      if (values[key] !== currentData[key]) {
        acc[key] = values[key] || "";
      }
      return acc;
    }, {});

    if (Object.keys(body).length > 0) {
      body.id = currentData.id;

      try {
        await REQUESTS.SETTINGS_CONTACTS.PUT(body);
        getCurrentData();
        message.success(translate("success"));
        setTimeout(() => setLoading(false), 1000);
        setDisable(true);
      } catch (error) {
        message.error(error);
        setLoading(false);
        setDisable(true);
      }
    } else {
      setLoading(false);
      setDisable(true);
    }
  };
  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      initialValues={{
        is_visibale: true,
      }}
      onValuesChange={() => setDisable(false)}
    >
      <Divider
        orientation="left"
        style={{ fontSize: "15px", display: "flex", alignItems: "baseline" }}
      >
        <div style={{ display: "flex", gap: "12px", lineHeight: 2 }}>
          <h4> {translate("Contacts")}</h4>
          <Form.Item name="is_visibale" valuePropName="checked">
            <Switch />
          </Form.Item>
        </div>
      </Divider>

      <div className="settings-contacts">
        <Form.Item label={translate("Phone")} name="phone">
          <Input />
        </Form.Item>
        <Form.Item
          label={translate("Email")}
          name="email"
          rules={[
            {
              required: false,
              message: translate(""),
            },
            {
              type: "email",
              message: translate("E-mail isn't valid"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={translate("Web page")}
          name="web_page"
          rules={[
            {
              required: false,
              message: translate(""),
            },
            {
              type: "url",
              message: translate("That's not a valid URL"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="WhatsApp"
          name="whatsapp"
          rules={[
            {
              required: false,
              message: translate(""),
            },
            {
              type: "url",
              message: translate("That's not a valid URL"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Telegram"
          name="telegram"
          rules={[
            {
              required: false,
              message: translate(""),
            },
            {
              type: "url",
              message: translate("That's not a valid URL"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Facebook"
          name="facebook"
          rules={[
            {
              required: false,
              message: translate(""),
            },
            {
              type: "url",
              message: translate("That's not a valid URL"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="TikTok"
          name="tiktok"
          rules={[
            {
              required: false,
              message: translate(""),
            },
            {
              type: "url",
              message: translate("That's not a valid URL"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={translate("Join Telegram group")}
          name="join_telegram_group"
          rules={[
            {
              required: false,
              message: translate(""),
            },
            {
              type: "url",
              message: translate("That's not a valid URL"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={translate("Join WhatsApp group")}
          name="join_whatsapp_group"
          rules={[
            {
              required: false,
              message: translate(""),
            },
            {
              type: "url",
              message: translate("That's not a valid URL"),
            },
          ]}
        >
          <Input />
        </Form.Item>
      </div>

      <Form.Item label={translate("Notes")} name="notes">
        <Input.TextArea rows={4} style={{ maxWidth: "350px" }} />
      </Form.Item>

      <Form.Item>
        <ButtonComponent
          htmlType="submit"
          loading={loading}
          disabled={disable}
          style={{ maxWidth: "350px" }}
        >
          {translate("Save")}
        </ButtonComponent>
      </Form.Item>
    </Form>
  );
}

import React, { useState, useEffect } from "react";
import { Drawer, Result } from "antd";
import REQUESTS from "../../../api/requests";
import useTranslate from "../../../hooks/translator";

export default function CurrentDevice({ open, onCancel, current }) {
  const translate = useTranslate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  console.log(current);

  const getData = () => {
    setLoading(true);
    REQUESTS.DEVICE_INFO({ id: current?.id })
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch(() => {});
  };
  return (
    <Drawer
      forceRender
      open={open}
      onClose={onCancel}
      placement="right"
      title={translate("Device info")}
      size="large"
    >
      {data?.black_list && (
        <div>
          <h2>{translate("Blacklist")}</h2>
          <div className="devices-total-info">
            <p>
              <span>{translate("device id")}:</span>{" "}
              {data?.black_list?.device_id || "_"}
            </p>
            <p>
              <span>{translate("provider id")}:</span>{" "}
              {data?.black_list?.provider_id || "_"}
            </p>
            <p>
              <span>{translate("Device key")}:</span>{" "}
              {data?.black_list?.device_key || "_"}
            </p>
            <p>
              <span>{translate("device os")}:</span>{" "}
              {data?.black_list?.device_os || "_"}
            </p>
            <p>
              <span>{translate("Device UUID")}:</span>{" "}
              {data?.black_list.device_uuid || "_"}
            </p>
            <p>
              <span>{translate("Created date")}:</span>{" "}
              {data?.black_list?.createdAt || "_"}
            </p>

            <p>
              <span>{translate("Updated date")}:</span>{" "}
              {data?.black_list?.updatedAt || "_"}
            </p>
          </div>
        </div>
      )}
      {data?.server && (
        <div style={{ marginTop: "20px" }}>
          <h2>{translate("Server info")}</h2>
          <div className="devices-total-info">
            <p>
              <span>{translate("Name")}:</span> {data?.server?.name || "_"}
            </p>

            <p>
              <span>{translate("Host")}:</span> {data?.server?.host || "_"}
            </p>
          </div>
        </div>
      )}{" "}
      {!loading && !data?.black_list && !data?.server && (
        <Result
          status="404"
          title="404"
          subTitle="Sorry, no information about this device could be found."
        />
      )}
    </Drawer>
  );
}

const PATHS = {
  // a
  APP_RESET_DEVICES: "/dashboard/app-reset-devices",
  APP_BRANDING: "app-branding",
  // b
  BILLING_PAGE: "billing-page",
  BRANDING_PAGE: "branding-page",
  BANNER_AD: "banner-ad",
  BLOCKED_DEVICES: "/dashboard/blocked-devices",
  BUY_CREDIT: "buy-credit",
  // c
  // d
  DASHBOARD: "/dashboard",

  DASHBOARD_UPGRADE: "/dashboard/upgrade",
  DEVICES_PAGE: "devices",
  DEVICES_BLACK_LIST: "/dashboard/black-lists",
  DEVICES_RESET_REQUESTS: "/dashboard/reset-requests",
  DEVICE_INFO: "device",
  DEACTIVATED_DEVICES: "/dashboard/deactivated-devices",
  // e
  ERROR_LOGS: "/dashboard/error-logs",
  // f
  FORGOT_PASSWORD: "/forgot-password",
  // g
  // h
  // i
  // j
  // k
  // l
  LOGIN: "/login",
  LIVES: "/dashboard/lives",
  // m
  MOVIES: "/dashboard/movies",
  MIGRATION: "/dashboard/migration",
  // n
  // NOTIFICATION: "notification-page",
  // o
  // p
  // q
  // r
  RESET_PASSWORD: "reset-password",
  // s
  SERIES: "/dashboard/series",
  SERVER_HOST: "/dashboard/server-hosts",
  SUBRESELLERS: "/dashboard/subresellers",
  SETTINGS: "/dashboard/settings",
  // t
  // u
  PAYMENT: "payment",
  // v
  // w
  // x
  // y
  // z
};

export default PATHS;

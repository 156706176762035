import "./infinity-progress.scss";

function InfinityProgress() {
  return (
    <div class="infinity-progress">
      <div class="infinity-progress-container">
        <div class="infinity-progress-body"></div>
      </div>
    </div>
  );
}

export default InfinityProgress;

import React, { useContext } from "react";
import { BrandingContext } from "../BrandingContext";
import { IMAGES } from "../../../config";
import {
  BRANDING_LIVE_TV,
  BRANDING_LIVE_TV_CATEGORIES,
} from "../../../config/data";

import "../styles/liveTv.scss";

export default function LiveTv() {
  const { backgroundImage, primaryColor, secondaryColor } =
    useContext(BrandingContext);
  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImage?.url || backgroundImage})`,
        backgroundPosition: "center center",
        backgroundSize: "cover",
        backgroundRepeat: " no-repeat",
        position: "relative",
        backgroundColor: "#000",
      }}
      className="live-tv-container display-width"
    >
      <div style={{ width: "100%" }}>
        <div className="live-tv-container-header">
          <div
            className="live-tv-container-header_item"
            style={{ background: "#1e1f20" }}
          >
            <img
              src={IMAGES.CATEGORY}
              alt=""
              style={{ width: "20px", margin: "0 8px 1px 5px" }}
            />
            <span>All</span>
          </div>
          <div
            className="live-tv-container-header_item block2"
            style={{
              background: "#1e1f20",
            }}
          >
            <span>Monday/September/2023</span>
            12:46
          </div>
        </div>

        <div className="live-tv-container-main-content">
          <div className="left-sector">
            <div className="live-tv-container-main-content-item">
              {BRANDING_LIVE_TV_CATEGORIES.map((item, i) => (
                <div
                  className="live-tv-container-main-content-item single-film live-tv-border"
                  style={{
                    background: `${item.id == 3 ? primaryColor : "#161719"}`,

                    position: "relative",
                    width: "45px",
                    padding: "17px",
                  }}
                  key={item.id}
                >
                  <div>
                    <span>{item.name}</span>
                  </div>
                </div>
              ))}
            </div>

            <div className="live-tv-container-main-content-item">
              {BRANDING_LIVE_TV.map((item, i) => (
                <div
                  className="live-tv-container-main-content-item single-film live-tv-border"
                  style={{
                    background: `${item.id == 1 ? primaryColor : "#161719"}`,
                    border: `2px solid ${
                      item.id == 4 ? secondaryColor : "initial"
                    }`,
                    position: "relative",
                  }}
                  key={item.id}
                >
                  <span
                    style={{
                      display: "block",
                      margin: "9px",
                    }}
                  >
                    {item.id}
                  </span>
                  <div>
                    <img
                      src={item.icon}
                      alt=""
                      style={{ width: "20px", margin: "0 8px 1px 5px" }}
                    />
                    <span style={{ fontSize: "12px" }}>{item.name}</span>
                  </div>
                  <span
                    style={{
                      display: "flex",
                      position: "absolute",
                      right: "10px",
                      width: "15px",
                    }}
                  >
                    {item.favorit}
                  </span>
                  <span
                    style={{
                      display: "flex",
                      position: "absolute",
                      right: "10px",
                      width: "15px",
                    }}
                  >
                    {item.lock}
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div className="live-tv-container-main-content-item2">
            <div className="live-tv-container-main-content-item2_tv"></div>
            <div
              className="live-tv-container-main-content-item2_tv-live"
              style={{ background: "#1e1f20" }}
            >
              <span
                style={{ display: "block", padding: "22px", fontSize: "16px" }}
              >
                1
              </span>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <img
                  src={IMAGES.CHANNEL_1}
                  alt=""
                  style={{ width: "35px", margin: "0 8px 1px 0" }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "10px",
                    alignItems: "center",
                  }}
                >
                  <span>DE:Sky Thriller HD+</span>
                </div>
              </div>
            </div>
            <div
              className="live-tv-container-main-content-item2_bottom"
              style={{ background: "#1e1f20" }}
            >
              <div className="live-tv-container-main-content-item2_bottom_single-item">
                <div
                  style={{
                    background: "#008000",
                  }}
                  className="live-tv-container-main-content-item2_bottom_single-item-color"
                ></div>
                Favorites
              </div>
              <div className="live-tv-container-main-content-item2_bottom_single-item">
                <div
                  style={{
                    background: "#ec7573",
                  }}
                  className="live-tv-container-main-content-item2_bottom_single-item-color"
                ></div>
                Lock
              </div>

              <div className="live-tv-container-main-content-item2_bottom_single-item">
                <div
                  style={{
                    background: "#0000ff",
                  }}
                  className="live-tv-container-main-content-item2_bottom_single-item-color"
                ></div>
                Menu
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { useLayoutEffect, useState } from "react";

import { Form, Drawer, message } from "antd";

import REQUESTS from "../api/requests";

import useTranslate from "../hooks/translator";

import {
  ButtonComponent,
  InputComponent,
  MessageComponent
} from "../components";

import "../styles/header.scss";

export default function ResetProfilePasswordDrawer({ onClose, open }) {
  const translate = useTranslate();

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const [messageErr, setMessageErr] = useState("");

  useLayoutEffect(() => {
    form.resetFields();
  }, [open]);

  const onFinish = (values) => {
    if (!open) return;

    setLoading(true);
    setMessageErr("");

    REQUESTS.CHANGE_PASSWORD(values)
      .then((data) => {
        onClose();
        message.success(translate("Password changed"));
        setLoading(false);
      })
      .catch((err) => {
        if (typeof err === "string") {
          if (
            err == "Wrong password" ||
            err == "Your old password and new password are same"
          ) {
            setMessageErr(translate(err));
          } else {
            setMessageErr(err);
          }
        }
        setLoading(false);
      });
  };

  const onFinishFailed = () => {
    setLoading(false);
  };

  const onValuesChange = () => {
    setMessageErr("");
    setLoading(false);
  };

  return (
    <Drawer
      open={open}
      width={350}
      onClose={onClose}
      title={translate("Reset password")}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={onValuesChange}
      >
        <Form.Item
          name="password"
          label={translate("Old password")}
          rules={[
            {
              required: true,
              message: translate("Please input your password")
            },
            {
              min: 8,
              message: translate("Password must be minimum 8 characters")
            }
          ]}
        >
          <InputComponent type="password" placeholder={translate("Password")} />
        </Form.Item>

        <Form.Item
          name="newPassword"
          label={translate("New password")}
          rules={[
            {
              required: true,
              message: translate("Please input your new password")
            },
            {
              min: 8,
              message: translate("Password must be minimum 8 characters")
            }
          ]}
        >
          <InputComponent type="password" placeholder={translate("Password")} />
        </Form.Item>

        <MessageComponent message={messageErr} />

        <Form.Item>
          <ButtonComponent htmlType="submit" loading={loading}>
            {translate("Reset my password")}
          </ButtonComponent>
        </Form.Item>
      </Form>
    </Drawer>
  );
}

import React from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "antd";

export default function TooltipLongText({
  children,
  afterChildren,
  to,
  isMobile,
  style,
}) {
  return (
    <Tooltip
      placement="right"
      title={!isMobile && children?.length > 17 ? children : null}
    >
      <div className="df-mneu-items">
        <Link to={to}>
          <p
            style={{
              maxWidth: isMobile ? "max-content" : "150px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              padding: "0px",
              margin: "0px",
              ...style,
            }}
            className="custom-menu-item"
          >
            {children}
          </p>
        </Link>
        {afterChildren}
      </div>
    </Tooltip>
  );
}

import React from "react";

export default function SettingsVersion({ path }) {
  return (
    <div>
      <div
        className="display-width settings-bg"
        style={{
          backgroundImage: `url(${path})`,
        }}
      />
    </div>
  );
}

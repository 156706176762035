import React from "react";
import { Upload, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { IMAGES } from "../config";

export default function ImageUpload({
  fileUrl,
  setFileUrl,
  width,
  height = "auto",
  maskClosable = true,
  imgErr = IMAGES.ERROR,
}) {
  const getBase64 = (file, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(file);
  };

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (url) => {
        setFileUrl({
          ...fileUrl,
          file: info.file.originFileObj,
          url: url,
        });
      });
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  return (
    <div className="img-upload" style={{ width: width, height: height }}>
      <Upload
        accept="image/*"
        customRequest={({ file, onSuccess }) => {
          setTimeout(() => {
            onSuccess("ok");
          }, 0);
        }}
        listType="picture-card"
        showUploadList={false}
        maxCount={1}
        onChange={handleChange}
      >
        {fileUrl?.url || fileUrl ? (
          <img
            src={fileUrl?.url || fileUrl}
            alt=""
            style={{
              width: "100%",
              height: height,
              objectFit: "contain",
              objectPosition: "center",
            }}
            onError={(e) => {
              e.target.src = imgErr;
            }}
          />
        ) : (
          <div
            style={{
              width: width,
              height: height,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PlusOutlined />
          </div>
        )}
        {maskClosable && (
          <div className="img-upload-hover-mask">
            <div>
              <PlusOutlined />
            </div>
          </div>
        )}
      </Upload>
    </div>
  );
}

import React from "react";
import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export default function confirmModal({
  title,
  content,
  okText = "Yes",
  cancelText = "Cancel",
  icon = <ExclamationCircleOutlined />,
  action,
  className,
}) {
  return Modal.confirm({
    className: className,
    icon: icon,
    title: title,
    okText: okText,
    cancelText: cancelText,
    content: content,
    okType: "danger",
    onOk: action,
  });
}
